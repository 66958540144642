import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'; // Import Routes and Route
import ActivateNews from './ActivateNews';
import MatchupsPage from './Matchups';
import { Header, Footer, QBxHeader, QBxFooter, B10Footer, B10Header, CFPFooter, CFPHeader } from './Global';
import { Leaders, Weekly } from './Leaders';
import { Groups, News, NewsForm } from './OtherPages';
import Profile from './Profile';
import { SimplePage, BigTenRules, QBxRules } from './help';
import ProcessLeaderboard from './ProcessLeaderboard';
import Home from './Home';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { fetchUserProfile } from './services/api';
import AddToHomeScreenButton from './HomeScreen';
import NewGame from './NewGame';
import { Auction, QBxLeaders } from './Auction';
import B10Leaders from './B10Leaders';
import { More, PlayoffProjection } from './More';
import Wrapped from './Wrapped';



import TeamRankings from './TeamRankings';
import 'firebase/storage';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBEIWLJMhCbfeAImiAzhnNxqioaaHNiKDw",
    authDomain: "our-cursor-419515.firebaseapp.com",
    projectId: "our-cursor-419515",
    storageBucket: "our-cursor-419515.appspot.com",
    messagingSenderId: "862090443322",
    appId: "1:862090443322:web:d9cfd958b990f8d5055303",
    measurementId: "G-WVK25PX7W2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const App = () => {
    const [currentPage, setCurrentPage] = useState('games');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();

    // Function to refresh access token
    const refreshAccessToken = async () => {
        // Your token refreshing logic here
    };

    // Check if user is logged in on component mount
    useEffect(() => {
        const loggedIn = localStorage.getItem('login') === '1';
        setIsLoggedIn(loggedIn);
        if (loggedIn) {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            setUser(storedUser);
        }
    }, []);

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUser(codeResponse);
            setIsLoggedIn(true);
            localStorage.setItem('currentPage', 'games');
            localStorage.setItem('login', '1');
            localStorage.setItem('user', JSON.stringify(codeResponse));
            setCurrentPage('games');
        },
        onError: (error) => console.log('Login Failed:', error)
    });


    useEffect(() => {
        const storedPage = localStorage.getItem('currentPage');
        if (storedPage) {
            setCurrentPage(storedPage);
        }
    }, []);

    useEffect(() => {
        if (user && (!localStorage.getItem('profile'))) {
            axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                const fetchData = async () => {
                    try {
                        const userProfile = await fetchUserProfile(res, setProfile);
                        setProfile(userProfile);
                        localStorage.setItem('profile', JSON.stringify(userProfile));
                    }
                    catch {

                    }
                }

                fetchData();
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    refreshAccessToken();
                }
            });
        }
    }, [user, profile]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        localStorage.setItem('currentPage', page); // Store current page in localStorage
    };

    const handleLogout = () => {
        googleLogout();
        setIsLoggedIn(false);
        setUser(null);
        setProfile(null);
        localStorage.removeItem('profile');
        localStorage.removeItem('login');
        localStorage.removeItem('user');
        localStorage.removeItem('currentPage'); // Remove stored page upon logout

        navigate('/');
    };

    const renderPage = () => {
        if ((isLoggedIn || localStorage.getItem('login'))) {
            switch (currentPage) {
                case 'games':
                    return (
                        <>
                        <Header onPageChange={handlePageChange} />
                            <MatchupsPage db={database} onPageChange={handlePageChange} />
                            <Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                case 'groups':
                    return (
                        <>
                        <Header onPageChange={handlePageChange} />
                            <Groups />
                            <Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                case 'help':
                    return (
                        <>
                        <Header onPageChange={handlePageChange} />
                            <SimplePage />
                            <Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                case 'helpb10':
                    return (
                        <>
                            <B10Header onPageChange={handlePageChange} />
                            <BigTenRules />
                            <B10Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                case 'helpQBX':
                    return (
                        <>
                            <QBxHeader onPageChange={handlePageChange} />
                            <QBxRules />
                            <QBxFooter currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                case 'leaderboard':
                    return (
                        <>
                        <Header onPageChange={handlePageChange} />
                            <Leaders onPageChange={handlePageChange} />
                            <Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                 case 'b10leaders':
                    return (
                        <>
                            <B10Header />
                            <B10Leaders onPageChange={handlePageChange} />
                            <B10Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                case 'weekly':
                    return (
                        <>
                        <Header onPageChange={handlePageChange} />
                            <Weekly onPageChange={handlePageChange} />
                            <Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                case 'profile':
                    return (
                        <>
                            <Header onPageChange={handlePageChange} />
                            <Profile onPageChange={handlePageChange} db={database} user={user} profile={profile} onLogout={handleLogout} />
                            <Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                 case 'news':
                    return (
                        <>
                        <Header onPageChange={handlePageChange} />
                            <News onPageChange={handlePageChange} />
                            <Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                    case 'newsForm':
                    return (
                        <>
                        <Header onPageChange={handlePageChange} />
                            <NewsForm onPageChange={handlePageChange} />
                            <Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                case 'nfl-teams':
                    return (
                        <>
                            <Header onPageChange={handlePageChange} />
                            <TeamRankings type="nfl" />
                            <Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                    case 'ncaa-teams':
                    return (
                        <>
                            <B10Header />
                            <TeamRankings type="ncaa" />
                            <B10Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
                    case 'newGame':
                        return (
                            <>
                                <B10Header />
                            <NewGame  db={database} onPageChange={handlePageChange} type="ncaa" />
                            <B10Footer currentPage={currentPage} onPageChange={handlePageChange} />
                            </>
                        );
                        case 'auctionGame':
                        return (
                            <>
                            <QBxHeader onPageChange={handlePageChange} league="auction" />
                            <Auction />
                            <QBxFooter currentPage={currentPage} onPageChange={handlePageChange} />
                            </>
                    )
                case 'qbxLeaders':
                    return (
                        <>
                            <QBxHeader onPageChange={handlePageChange} league="auction" />
                            <QBxLeaders />
                            <QBxFooter currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    )
                case 'more':
                    return (
                        <>
                            <Header onPageChange={handlePageChange} />
                            <More onPageChange={handlePageChange} />
                            <Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    )
                case 'wrapped':
                    return (
                        <>
                            <Wrapped />
                            <CFPFooter currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    )
                    case 'cfp':
                    return (
                        <>
                            <CFPHeader />
                            <PlayoffProjection />
                            <CFPFooter currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    )
                default:
                    return (
                        <>
                            <Header onPageChange={handlePageChange} />
                            <MatchupsPage db={database} onPageChange={handlePageChange} />
                            <Footer currentPage={currentPage} onPageChange={handlePageChange} />
                        </>
                    );
            }
        } else {
            // Render login page if not logged in
            //return <Login onLogin={handleLogin} />;
            return (
                <AddToHomeScreenButton isLoggedIn={isLoggedIn} login={login} />
                /*
                <div>
                    {(isLoggedIn || localStorage.getItem('login')) ? (
                        <div>
                            <Header />
                        </div>
                    ) : (
                        <div>
                            <div className="login-background"></div>
                            <div className="login">
                                    <h1>Welcome to <span>GIBBS</span></h1>
                                    <AddToHomeScreenButton />

                                <p>Make your NFL game predictions - Earn Points - Beat your friends!</p>
                                <button onClick={login}>Sign in with Google</button>
                            </div>
                        </div>
                    )}
                </div>
                */
            )
        }
    };

    return (
        <div>
            <Routes>
                <Route path="/trigger-script" element={<ProcessLeaderboard />} />
                <Route path="/active-password-1234" element={<ActivateNews />} />
                <Route path="/" element={<Home />} />
            </Routes>
            {renderPage()}
        </div>
    );
};

export default App;
