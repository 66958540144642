import React, { useState } from 'react';


const Header = ( {onPageChange, league }) => {
    return (
            <div className="header">
                <div className="logo" onClick={() => onPageChange(league=="ncaa" ? "newGame" : "help")}>
                    <img src='Group 17.png' alt="logo" />
                </div>
                <div className="mainname"><h1>GIBBS {league=="ncaa" ? "B10" : ""}</h1></div>
        <div className="help" onClick={() => onPageChange("profile")}>
                    <img src='settings.png' alt="help" />
                </div>
            </div>
        );
}

const B10Header = ( { }) => {
    return (
            <div className="header">
                <div>
                </div>
                <div className="mainname"><h1>GIBBS B10</h1></div>
                <div>
                </div>
            </div>
        );
}

const CFPHeader = ( { }) => {
    return (
            <div className="header">
                <div>
                </div>
                <div className="mainname"><h1>CFP Projection</h1></div>
                <div>
                </div>
            </div>
        );
}

const QBxHeader = ( {onPageChange, league }) => {
    return (
            <div className="header">
                
            <div className="mainname qbx">
                <svg width="40" height="40" viewBox="0 0 218 254" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M110.414 0.105225L181.125 0.105225L216.48 35.4606L181.125 70.8159L110.414 0.105225Z" fill="red" />
                <path d="M36.8752 0.105228L107.586 0.105228L142.941 35.4606L107.586 70.8159L36.8752 0.105228Z" fill="red" />
                <path d="M0.105655 107.585L0.105652 36.8748L35.461 1.51947L70.8163 36.8748L0.105655 107.585Z" fill="red" />
                <path d="M0.105658 181.125L0.105655 110.414L35.461 75.0586L70.8163 110.414L0.105658 181.125Z" fill="red" />
                <path d="M107.586 217.894L36.8753 217.894L1.51994 182.539L36.8753 147.184L107.586 217.894Z" fill="red" />
                <path d="M145.77 253.25L145.77 182.539L110.414 147.184L75.059 182.539L145.77 253.25Z" fill="red" />
                <path d="M217.895 110.414V181.125L182.539 216.48L147.184 181.125L217.895 110.414Z" fill="red" />
                <path d="M217.895 36.8751L217.895 107.586L182.539 142.941L147.184 107.586L217.895 36.8751Z" fill="red" />
            </svg>
</div>
            <div></div>
                <div></div>
            </div>
        );
}

const B10Footer = ({ currentPage, onPageChange }) => {
    var [activePage, setActivePage] = useState(null);

    const footerClick = (page) => {
        setActivePage(page);
        onPageChange(page); // Update the current page in the App component
    };

    activePage = currentPage;

    return (
        <div className="footer">
            <div
                className={`footer-games ${activePage === "newGame" ? 'active-icon' : ''}`}
                onClick={() => footerClick("newGame")}
            >
                <img src='games.png' alt="Games" />
                <span>Picks</span>
            </div>
             <div
                className={`footer-news ${activePage === "ncaa-teams" ? 'active-icon' : ''}`}
                onClick={() => footerClick("ncaa-teams")}
            >
                <img src='qbx.png' alt="ncaa-teams" />
                <span>Teams</span>
            </div>
           
            <div
                className={`footer-leaderboard ${activePage === "b10leaders" || activePage === "b10leaders" ? 'active-icon' : ''}`}
                onClick={() => footerClick("b10leaders")}
            >
                <img src='leaderboard.png' alt="Leaders" />
                <span>Leaders</span>
            </div>
            {<div
                className={`footer-news ${activePage === "helpb10" ? 'active-icon' : ''}`}
                onClick={() => footerClick("helpb10")}
            >
                <img src='play.png' alt="News" />
                <span>Rules</span>
            </div>}
            {<div
                className={`footer-groups`}
                onClick={() => footerClick("more")}
            >
                <img src='exit.png' alt="Exit" />
                <span>Back</span>
            </div>}
        </div>
    );
}

const Footer = ({ currentPage, onPageChange }) => {
    var [activePage, setActivePage] = useState(null);

    const footerClick = (page) => {
        setActivePage(page);
        onPageChange(page); // Update the current page in the App component
    };

    activePage = currentPage;

    return (
        <div className="footer">
            <div
                className={`footer-games ${activePage === "games" ? 'active-icon' : ''}`}
                onClick={() => footerClick("games")}
            >
                <img src='games.png' alt="Games" />
                <span>Picks</span>
            </div>

             <div
                className={`footer-news ${activePage === "news" ? 'active-icon' : ''}`}
                onClick={() => footerClick("news")}
            >
                <img src='profile.png' alt="News" />
                <span>News</span>
            </div>
           
            <div
                className={`footer-leaderboard ${activePage === "leaderboard" || activePage === "weekly" ? 'active-icon' : ''}`}
                onClick={() => footerClick("weekly")}
            >
                <img src='leaderboard.png' alt="Leaders" />
                <span>Leaders</span>
            </div>
            {/*<div
                className={`footer-news ${activePage === "help" ? 'active-icon' : ''}`}
                onClick={() => footerClick("help")}
            >
                <img src='play.png' alt="News" />
                <span>Rules</span>
            </div>*/}
            {<div
                className={`footer-more ${activePage === "more" ? 'active-icon' : ''}`}
                onClick={() => footerClick("more")}
            >
                <img src='groups.png' alt="More" />
                <span>More</span>
            </div>}
        </div>
    );
}

const QBxFooter = ({ currentPage, onPageChange }) => {
    var [activePage, setActivePage] = useState(null);

    const footerClick = (page) => {
        setActivePage(page);
        onPageChange(page); // Update the current page in the App component
    };

    activePage = currentPage;

    return (
        <div className="footer">
            <div
                className={`footer-games ${activePage === "auctionGame" ? 'active-icon' : ''}`}
                onClick={() => footerClick("auctionGame")}
            >
                <img src='QBx.png' alt="auctionGame" />
                <span>QBs</span>
            </div>

            <div
                className={`footer-leaderboard ${activePage === "qbxLeaders" ? 'active-icon' : ''}`}
                onClick={() => footerClick("qbxLeaders")}
            >
                <img src='leaderboard.png' alt="Leaders" />
                <span>Leaders</span>
            </div>
            <div
                className={`footer-news ${activePage === "helpQBX" ? 'active-icon' : ''}`}
                onClick={() => footerClick("helpQBX")}
            >
                <img src='play.png' alt="News" />
                <span>Rules</span>
            </div>
            {<div
                className={`footer-groups`}
                onClick={() => footerClick("more")}
            >
                <img src='exit.png' alt="Exit" />
                <span>Back</span>
            </div>}
        </div>
    );
}

const CFPFooter = ({ currentPage, onPageChange }) => {
    var [activePage, setActivePage] = useState(null);

    const footerClick = (page) => {
        setActivePage(page);
        onPageChange(page); // Update the current page in the App component
    };

    activePage = currentPage;

    return (
        <div className="footer">
            {<div
                className={`footer-groups`}
                onClick={() => footerClick("more")}
            >
                <img src='exit.png' alt="Exit" />
                <span>Back</span>
            </div>}
        </div>
    );
}

export { Header, Footer, QBxHeader, QBxFooter, B10Footer, B10Header, CFPFooter, CFPHeader }; // Exporting Header and Footer components
