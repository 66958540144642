import React, { useState, useEffect } from 'react';
import { fetchAllPicksFromWeek } from '../../services/api';

export const MatchupPicks = ({ week, matchup }) => {
    const [picksData, setPicksData] = useState([]);
    const [homeCount, setHomeCount] = useState(0);
    const [awayCount, setAwayCount] = useState(0);

    const homeTeam = matchup.competitions[0]?.competitors[0]?.team.name || 'Team Not Available';
    const awayTeam = matchup.competitions[0]?.competitors[1]?.team.name || 'Team Not Available';

    useEffect(() => {
        const fetchData = async () => {
            try {
                var realWeek = week;
                if (matchup.id == "401671610") {
                    realWeek = realWeek - 6;
                }

                const data = await fetchAllPicksFromWeek(realWeek);

                // Sort data by 'order' field first, then by 'Name'
                const sortedData = data.sort((a, b) => {
                    // Sort by 'order' field if it exists, otherwise by 'Name'
                    const orderA = a.Order || 0; // Treat missing 'order' as a high value
                    const orderB = b.Order || 0; // Treat missing 'order' as a high value
                    if (orderA !== orderB) return orderB - orderA;

                    // Secondary sort by 'Name'
                    const nameA = a.Name.toUpperCase();
                    const nameB = b.Name.toUpperCase();
                    return nameA.localeCompare(nameB);
                });

                setPicksData(sortedData);

                setPicksData(sortedData);
            } catch (error) {
                console.error("Error fetching picks data:", error);
            }
        };

        fetchData();
    }, [week]);

    // Extract values of a specific field from each item in picksData
    const extractFieldValues = (fieldName, shouldParse) => {
        return picksData.map(item => {
            const value = item[fieldName];
            return shouldParse ? JSON.parse(value) : value;
        });
    };

    // Example usage: extract values of the 'Picks' field without parsing
    const fieldValueArray = extractFieldValues('Picks', true);

    // Example usage: extract values of the 'Name' field and parse them
    const usersValueArray = extractFieldValues('Name', false);

    // Count values displayed for Home and Away teams
    useEffect(() => {
        let homeCount = 0;
        let awayCount = 0;

        fieldValueArray.forEach((value, key) => {
            if (value[matchup.id] && homeTeam === value[matchup.id].pick) {
                homeCount++;
            } else if (value[matchup.id] && awayTeam === value[matchup.id].pick) {
                awayCount++;
            }
        });

        setHomeCount(homeCount);
        setAwayCount(awayCount);
    }, [fieldValueArray, homeTeam, awayTeam, matchup.id]);


    return (
        <div className="matchup-picks">
            {awayCount + homeCount > 0 ? (<div><h2>Who Picked Who?</h2></div>): (<div></div>)}
            {awayCount + homeCount > 0 ? (
                
                <div className="counts">
                    

            <div className="home">
                {homeCount > 0 && <div className="count" style={{ width: `calc(${100 * homeCount / (awayCount + homeCount)}% - 6px)` }}>{homeCount}</div>}
                {//homeCount === 0 && <div className="count" style={{ color: "black", background: "transparent" }}>0</div>}
                }
                {Object.entries(fieldValueArray).map(([key, value]) => (
                    value[matchup.id] && homeTeam === value[matchup.id].pick ? (
                        <div key={key}>
                            {usersValueArray[key]}
                            <span className={picksData[key].Color}><img alt="Profile" src={picksData[key].Icon + ".png"} /></span>
                        </div>
                    ) : null
                ))}
            </div>
            <div className="away">
                {awayCount > 0 && <div className="count" style={{ width: `calc(${100 * awayCount / (awayCount + homeCount)}% - 6px)` }}>{awayCount}</div>}
                {//awayCount === 0 && <div className="count" style={{ color: "black", background: "transparent" }}>0</div>}
                }
                {Object.entries(fieldValueArray).map(([key, value]) => (
                    value[matchup.id] && awayTeam === value[matchup.id].pick ? (
                        <div key={key}>
                            <span className={picksData[key].Color}><img alt="Profile" src={picksData[key].Icon + ".png"} /></span>
                            {usersValueArray[key]}
                        </div>
                    ) : null
                ))}
                    </div>
                </div>
            ) : (
                    <div></div>
                    )
            }
        </div>
    );

};
