import React, { useState, useEffect, useCallback } from 'react';

const CountdownTimer = ({ start }) => {
    const getTimeRemaining = useCallback(() => {
        const now = new Date().getTime();
        const distance = start.getTime() - now;

        if (distance <= 0) {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return {
            days,
            hours,
            minutes,
            seconds
        };
    }, [start]);

    const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining(getTimeRemaining());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [getTimeRemaining]);

    return (
        <div className="countdown-timer">
            <div className="countdown-item">
                {timeRemaining.days}
                <span className="sub">days</span>
            </div>
            <div className="countdown-item">
                {timeRemaining.hours}
                <span className="sub">hours</span>
            </div>
            <div className="countdown-item">
                {timeRemaining.minutes}
                <span className="sub">minutes</span>
            </div>
            <div className="countdown-item">
                {timeRemaining.seconds}
                <span className="sub">seconds</span>
            </div>
        </div>
    );
};

export default CountdownTimer;
