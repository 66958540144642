import React, { useState, useEffect, useRef } from 'react';
import './WeekButtons.css';

const WeekButtons = ({ handleWeekClick, status }) => {
    const [activeWeek, setActiveWeek] = useState(null);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        const currentDate = new Date();
        const weeks = [];
        const dates = [
            /*'Aug 1 - Aug 5',
            'Aug 6 - Aug 12',
            'Aug 13 - Aug 19',
            'Aug 20 - Aug 26',*/
            'Aug 1 - Sep 9',
            'Sep 10 - Sep 16',
            'Sep 17 - Sep 23',
            'Sep 24 - Sep 30',
            'Oct 1 - Oct 7',
            'Oct 8 - Oct 14',
            'Oct 15 - Oct 21',
            'Oct 22 - Oct 28',
            'Oct 29 - Nov 4',
            'Nov 5 - Nov 11',
            'Nov 12 - Nov 18',
            'Nov 19 - Nov 25',
            'Nov 26 - Dec 2',
            'Dec 3 - Dec 9',
            'Dec 10 - Dec 16',
            'Dec 17 - Dec 23',
            'Dec 24 - Dec 30',
            'Dec 31 - Jan 6',
            'Jan 7 - Jan 13',
            'Jan 14 - Jan 20',
            'Jan 21 - Jan 27',
            'May 1 - May 2',
            'Jan 28 - Feb 28',
            // Add more custom date ranges as needed
        ];



        dates.forEach((dateRange, index) => {
            const [startDateStr, endDateStr] = dateRange.split(' - ');
            var year = "2024";
            if (startDateStr.includes("Jan") || startDateStr.includes("Feb")) {
                year = "2025";
            }
            const startDate = new Date(startDateStr + ", " + year);
            if (endDateStr.includes("Jan") || endDateStr.includes("Feb")) {
                year = "2025";
            }
            else {
                year = "2024";
            }
            const endDate = new Date(endDateStr + ", " + year);
            endDate.setDate(endDate.getDate() + 1); // Including end date
            weeks.push({ startDate, endDate });
        });

        var weekIndex = weeks.findIndex((week) => currentDate >= week.startDate && currentDate <= week.endDate);

        if (weekIndex === -1) {
            weekIndex = 0;
        }
        //weekIndex -= 6;
        handleButtonClick(weekIndex + 1);
        /*setActiveWeek(weekIndex + 1);
        handleWeekClick(weekIndex + 1);
        weekIndex = Math.min(weekIndex, 22);
        // Scroll to the center of the active button
        if (scrollContainerRef.current) {
            const containerWidth = scrollContainerRef.current.offsetWidth;
            const button = scrollContainerRef.current.children[weekIndex];
            const buttonWidth = button.offsetWidth;
            const scrollLeft = button.offsetLeft - (containerWidth / 2) + (buttonWidth / 2);
            scrollContainerRef.current.scrollLeft = scrollLeft;
        }*/
    }, []);

    const handleButtonClick = (index) => {
        setActiveWeek(index);
        handleWeekClick(index);
        index = Math.min(index, 22);

        // Scroll to the center of the clicked button
        if (scrollContainerRef.current && index > -6) {
            const containerWidth = scrollContainerRef.current.offsetWidth;
            if (index < 0) {
                index += 2;
            }
            const button = scrollContainerRef.current.children[index - 1 + 0];
            const buttonWidth = button.offsetWidth;
            const scrollLeft = button.offsetLeft - (containerWidth / 2) + (buttonWidth / 2);
            scrollContainerRef.current.scrollLeft = scrollLeft;
        }
    };

    const dates = [
        'Sep 3 - Sep 9',
        'Sep 10 - Sep 16',
        'Sep 17 - Sep 23',
        'Sep 24 - Sep 30',
        'Oct 1 - Oct 7',
        'Oct 8 - Oct 14',
        'Oct 15 - Oct 21',
        'Oct 22 - Oct 28',
        'Oct 29 - Nov 4',
        'Nov 5 - Nov 11',
        'Nov 12 - Nov 18',
        'Nov 19 - Nov 25',
        'Nov 26 - Dec 2',
        'Dec 3 - Dec 9',
        'Dec 10 - Dec 16',
        'Dec 17 - Dec 23',
        'Dec 24 - Dec 30',
        'Dec 31 - Jan 6',
        // Add more custom date ranges as needed
    ];
    const weekButtons = dates.map((dateRange, index) => (
        <button
            key={index + 1}
            className={`${activeWeek === index + 1 ? 'active-week' : ''} ${status === "loading" ? 'disabled' : ''}`}
            onClick={() => handleButtonClick(index + 1)}
        >
            Week {index + 1}
            <br />
            <span className="week-dates">{dateRange}</span>
        </button>
    ));

    return (
        <div className="week-buttons" ref={scrollContainerRef}>
            {/*<button className={`${activeWeek === -5 ? 'active-week preseason' : 'preseason'} ${status === "loading" ? 'disabled' : ''}`} onClick={() => handleButtonClick(-5)}>HOF<br /><span className="week-dates">Jul 30 - Aug 5</span></button>
            <button className={`${activeWeek === -4 ? 'active-week preseason' : 'preseason'} ${status === "loading" ? 'disabled' : ''}`} onClick={() => handleButtonClick(-4)}>Preseason 1<br /><span className="week-dates">Aug 6 - Aug 12</span></button>
            <button className={`${activeWeek === -3 ? 'active-week preseason' : 'preseason'} ${status === "loading" ? 'disabled' : ''}`} onClick={() => handleButtonClick(-3)}>Preseason 2<br /><span className="week-dates">Aug 13 - Aug 19</span></button>
            <button className={`${activeWeek === -2 ? 'active-week preseason' : 'preseason'} ${status === "loading" ? 'disabled' : ''}`} onClick={() => handleButtonClick(-2)}>Preseason 3<br /><span className="week-dates">Aug 20 - Aug 26</span></button>
            */}
            {weekButtons}
            <button
                className={`${activeWeek === 19 ? 'active-week' : ''} ${status === "loading" ? 'disabled' : ''}`}
                onClick={() => handleButtonClick(19)}>
                Wild Card
                <br />
                <span className="week-dates">Jan 7 - Jan 13</span>
            </button>
            <button className={`${activeWeek === 20 ? 'active-week' : ''} ${status === "loading" ? 'disabled' : ''}`} onClick={() => handleButtonClick(20)}>Div Rd<br /><span className="week-dates">Jan 14 - Jan 20</span></button>
            <button className={`${activeWeek === 21 ? 'active-week' : ''} ${status === "loading" ? 'disabled' : ''}`} onClick={() => handleButtonClick(21)}>Conf Champ<br /><span className="week-dates">Jan 21 - Jan 27</span></button>
            <button className={`${activeWeek === 23 ? 'active-week' : ''} ${status === "loading" ? 'disabled' : ''}`} onClick={() => handleButtonClick(23)}>Super Bowl<br /><span className="week-dates">Feb 9, 2025</span></button>
        </div>
    );
};

export default WeekButtons;