import React from 'react';

class SimplePage extends React.Component {
  render() {
    return (
      <div className="page help">
        <div className="profile-section">
          <h1>What is GIBBS?</h1>
          <p>GIBBS is a game where you compete to predict the winners of NFL games! Even if you know nothing about Football, you can still play!</p>
          <h1>How to Play?</h1>
          <p>Each week, open up the app and make your picks on who you think will win each game. Click the logos of the teams you think will win. At the bottom, there is a save button. (After you save, you can still make changes to your predictions until the game starts)</p>
          <p>Weeks open on Tuesday at 8:00 PM EST. The first game is typically on Thursday Night.</p>
          <p>The number on the team indicates the number of points you get for correctly picking them to win the game (The team that is favored earns less points than the underdog).</p>
          <p>At the end of the season, whoever has the most points wins!</p>
                <p>Note: If you played last year, there a couple of changes: </p>
                    <ul>
                        <li><b>+ Profile Page</b></li>
                        <li><b>+ News Page</b></li>
                        <li><b>+ Matchup Projections</b></li>
                        <li><b>+ GIBBS Power Ratings</b></li>
                        <li>No Featured Games</li>
                        <li>No Super Selections</li>
                    </ul>
               
      </div>
      </div>
    );
  }
}

class BigTenRules extends React.Component {
    render() {
        return (
            <div className="page help">
                <div className="profile-section">
                    <h1>What is GIBBS B10?</h1>
                    <p>GIBBS B10 is a game where you compete to predict the winners of Big Ten Football games! Even if you know nothing about Football, you can still play!</p>
                    <h1>How to Play?</h1>
                    <p>Make your picks on who you think will win each game. There is a slider beneath each game, where you can move the slider to assign your prediction to the game.</p>
                    <p>You earn points based on how confident your prediction is, but if you're wrong, you earn less points. See the below Examples.</p>
                    <p>At the bottom, there is a save button. (After you save, you can still make changes to your predictions until the game starts)</p>
                    <p>Michigan vs Michigan State: I select Michigan to win with a 89% degree of certainty. If I am correct, I will earn 89 AP (Accuracy Points), but if I am wrong, I will only gain 11 AP.</p>
                    <p>Iowa vs Wisconsin: I select Iowa to win with a 55% degree of certainty. If Iowa Wins, I will earn 55 AP, but if they lose, I will only gain 45 AP.</p>
                    <p>Oregon vs Ohio State: I select TIE (or 50%). No matter what happens, I will gain 50 AP.</p>
                    
                    <p>At the end of the season, whoever has the highest Prediction Accuracy* wins!</p>
                    <br></br>
                    <p>*Prediction Accuracy is calculated as follows: Points/PossiblePoints</p>
                </div>
            </div>
        );
    }
}

class QBxRules extends React.Component {
    render() {
        return <div className="page help">
            <div className="profile-section">
                <h1>What is QBx?</h1>
                <p><u>The QB eXchange is completely separate from GIBBS.</u> It is a stock trading game for enthusiastic NFL fans, where you can "buy"" Quarterbacks who are performing poorly and "sell" them when they improve</p>
                <h1>How to Play?</h1>
                <p>Buy and Sell NFL Quarterbacks to earn in-game money!</p>
                <p>Quarterbacks are valued based on the rolling average of their QBR* throughout the season, so their value fluctuates with their performances. Each quarterback can be Bought and Sold at anytime.</p>
                <p>Quarterbacks only receive a QBR rating for a game if they have 20 or more active plays in that game.</p>
                <p>*<i>QBR (Quarterback Rating) is a comprehensive metric that evaluates a quarterback's overall performance in a game, accounting for passing, rushing, and situational context on a scale from 0 to 100.</i></p>
            </div>
        </div>;
    }
}

export { SimplePage, BigTenRules, QBxRules };