import { collection, getDocs, getFirestore } from 'firebase/firestore';

export const getTeams = async () => {
    const firestore = getFirestore();
    // Reference to the 'Teams' collection
    const teamsCollection = collection(firestore, 'Teams');

    // Get the snapshot of the collection
    const snapshot = await getDocs(teamsCollection);

    // Process the snapshot to extract the data
    const fetchedData = snapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
    }, {});

    return fetchedData;
};

export const getCollegeTeams = async () => {
    const firestore = getFirestore();
    // Reference to the 'Teams' collection
    const teamsCollection = collection(firestore, 'B10Teams');
    
    // Get the snapshot of the collection
    const snapshot = await getDocs(teamsCollection);

    // Process the snapshot to extract the data
    const fetchedData = snapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
    }, {});

    return fetchedData;
};