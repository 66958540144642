import React, { useEffect, useState, useRef } from 'react';
import CountdownTimer from './components/Timer/Timer';
import MatchupContainer from './components/MatchupContainer/MatchupContainer';
import {getCollegeTeams} from './Teams'; // Assuming you've placed the function in a separate file
import { fetchData, fetchPicks, savePicks  } from './services/api';

const NewGame = ({ db, onPageChange }) => {
    const today = new Date();
    const seasonStart = new Date(2024, 7, 24);
    const diffInMs = today - seasonStart;
    const msInDay = 24 * 60 * 60 * 1000;
    const diffInDays = Math.floor(diffInMs / msInDay);

    

    var weekNumber = Math.ceil(diffInDays / 7);

    if (weekNumber < 2) {
        weekNumber = 2;
    }
    

    const [games, setGames] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [week, setWeek] = useState(weekNumber);
    const [start, setStart] = useState(0);
    const scrollContainerRef = useRef(null);
    const [isStarted, setIsStarted] = useState(false);
    const [isEnded, setIsEnded] = useState(false);
     const [teams, setTeams] = useState([]);
     const [selectedTeamIds, setSelectedTeamIds] = useState({}); // State to track selected teams
    const [picks, setPicks] = useState([]); // Declare the 'picks' array
    const [savedPicks, setSavedPicks] = useState({}); // Add this state

    useEffect(() => {
        // Fetch teams when the component mounts
        getCollegeTeams()
            .then((t) => {
                setTeams(t);
            })
            .catch((error) => {
                console.error('Error fetching teams:', error);
                // Handle error gracefully
            });
    }, [db]);

    useEffect(() => {
        const fetchGames = async (week) => {

            setLoading(true);
            setPicks({});
            const allGames = [];
            const url = `https://site.api.espn.com/apis/site/v2/sports/football/college-football/scoreboard?week=${week}&groups=5`;
            
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                //const startTimestamp = result.leagues[0].calendar[0].entries[week-1].startDate;
                const startTimestamp = 0;
                console.log(week);
                console.log(result.leagues[0].calendar[0].entries[week - 1]);
                const endTimestamp = result.leagues[0].calendar[0].entries[week-1].endDate;

                const startDate = new Date(startTimestamp);
                const endDate = new Date(endTimestamp);
                
                setStart(startDate);

                const now = new Date();
                if (now.getTime() > (startDate.getTime())) {
                    setIsStarted(true);
                }

                if (now.getTime() > endDate.getTime()) {
                    setIsEnded(true);

                }
                else {
                    setIsEnded(false);
                }

                setStart(startDate);

                const filteredGames = result.events.filter(game => 
                    game.competitions[0].conferenceCompetition
                );


                setGames(filteredGames);
            } catch (error) {
                setError(error);
            } finally {
                
            }

            try {
                await fetchPicks(week + 100, setPicks, setSelectedTeamIds, setSavedPicks);
               
                setLoading(false);
            } catch (error) {
                console.error('Error fetching picks:', error);
            }
        };

        fetchGames(week);
    }, [week]);

   const handleButtonClick = (index) => {
        setWeek(index);
       setPicks(null);
       setSelectedTeamIds(null);

        // Scroll to the center of the clicked button
        if (scrollContainerRef.current && index > -2) {
            const containerWidth = scrollContainerRef.current.offsetWidth;
            const button = scrollContainerRef.current.children[index - 1];
            const buttonWidth = button.offsetWidth;
            const scrollLeft = button.offsetLeft - (containerWidth / 2) + (buttonWidth / 2);
            scrollContainerRef.current.scrollLeft = scrollLeft;
        }
    };

    const dates = [
            'Aug 24 - Sep 2',
            'Sep 3 - Sep 7',
            'Sep 8 - Sep 14',
            'Sep 15 - Sep 21',
            'Sep 22 - Sep 28',
            'Sep 29 - Oct 5',
            'Oct 6 - Oct 12',
            'Oct 13 - Oct 19',
            'Oct 20 - Oct 26',
            'Oct 27 - Nov 2',
            'Nov 3 - Nov 9',
            'Nov 10 - Nov 16',
            'Nov 17 - Nov 23',
            'Nov 24 - Nov 30',
            /*'Dec 1 - Dec 7',
            'Dec 8 - Dec 14',*/
            // Add more custom date ranges as needed
        ];
    const weekButtons = dates.map((dateRange, index) => (
        <button
            key={index + 1}
            className={`${week === index + 1 ? 'active-week' : ''}`}
            onClick={() => handleButtonClick(index + 1)}
        >
            Week {index + 1}
            <br />
            <span className="week-dates">{dateRange}</span>
        </button>
    ));

    const handleTeamClick = (pick, matchupId, val) => {

        /*setSelectedTeamIds((prevSelectedTeamIds) => {
            return {
                ...prevSelectedTeamIds,
                [matchupId]: { "pick": pick, "val": val },
            };
        });*/
        
        setPicks((prevPicks) => {
            const newPicks = { ...prevPicks };
            newPicks[matchupId] = { "pick": pick, "val": val };
            return newPicks;
        });
    };

    const fauxClick  = (pick, matchupId, val) => {
        console.log("faux");
        };

    
    const handleSaveClick = async () => {
        const profile = JSON.parse(localStorage.getItem('profile'));
        const user = profile.Email;
        const confirmationDiv = document.getElementById('confirmation');
        try {

            await savePicks(picks, user, week+100);
            // Show confirmation div
            if(confirmationDiv != null){
                confirmationDiv.style.display = 'block';
                // Fade out after 3 seconds
                setTimeout(() => {
                    confirmationDiv.style.display = 'none';
                }, 3000);
            }            
        } catch (error) {
            console.error('Error saving picks:', error);
        }
    };
    
    //const [certaintyValue, setCertaintyValue] = useState(50);

    const changeCV = (newValue, id, pick) => {
        //setCertaintyValue(newValue);
        handleTeamClick(pick, id, newValue);
    };
    
      if (error) return <p>Error: {error.message}</p>;

      
    const pickCount = (picks == undefined || picks == null ? 0 : Object.keys(picks).length);

    return (
        <>
            <div className="week-buttons" ref={scrollContainerRef}>
                {weekButtons}
            </div>
            <div className={!isStarted ? `page unclickable!! bigten` : `page bigten`}>
                {isLoading && (<div><div className="loader"></div></div>)}
                {!isLoading && (
                    <>   
                        {!isStarted ? <div className="banner"> OPENS IN <CountdownTimer start={start} /></div> : isEnded ? <div className="banner">WEEK CLOSED</div> : ''}
                        
                        {games.length > 0 ?
                            games.map((game, index) => {
                                var defaultVal = false;
                                var val = 50;
                                if (picks != undefined && picks[game.id] !== undefined && picks[game.id].val != undefined) {
                                    console.log(game);
                                    val = picks[game.id].val;
                                    if (picks[game.id].pick == game.competitions[0].competitors[1].team.name) {
                                        val = 100 - val;
                                    }
                                }
                                else{
                                    defaultVal = true;
                                    const homeTeam = game.competitions[0]?.competitors[0]?.team || 'Team Not Available';
                                    const awayTeam = game.competitions[0]?.competitors[1]?.team || 'Team Not Available';

                                     if (teams[homeTeam.name] && teams[awayTeam.name]) {
                                        const hi = Object.keys(teams[homeTeam.name].rating).length - 1;
                                        const ai = Object.keys(teams[awayTeam.name].rating).length - 1;
        

                                        var homeVal = teams[homeTeam.name].rating[hi] * 1;
                                        homeVal += 1;

                                        var AwayVal = teams[awayTeam.name].rating[ai] * 1;
                                        AwayVal -= 1;

                                        var homeValUnrounded = homeVal - AwayVal;
                                        homeVal = homeVal - AwayVal;

                                        homeValUnrounded = 10 - Math.max(0.1, Math.min(9.9, ((3*homeVal/2) / 5 + 5)));
                                        //homeVal = Math.round(10 - Math.max(1, Math.min(9, (homeVal / 5 + 5))));

                                        val = 100-Math.round(homeValUnrounded * 10);

                                    }
                                }
                               

                                return (
                                    <>
                                        <MatchupContainer
                                        key={game.id}
                                        status=""
                                        matchup={game}
                                        handleTeamClick={fauxClick}
                                        selectedTeamIds={selectedTeamIds}
                                        teams={teams}
                                        onPageChange={onPageChange}
                                        type="ncaa"
                                        cv={val}
                                        change={changeCV}
                                        defaultVal={defaultVal}
                                    />
                                    </>
                                    )
                                }
                                            
                            ) : <div className="matchup-container"> <h4>There are no games scheduled for this week.</h4><br/></div>
                        }

                        
                        <div id="confirmation">Picks Saved.</div>
                                   {((!isEnded && isStarted) || false) && (
                            <div className="save-section">
                            <button id="mainsave" onClick={handleSaveClick}>
                                Save Progress
                            </button>
                            {/*<div className="pickProgress">
                            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 20 20">
                              <circle r="5" cx="10" cy="10" stroke="#999" strokeWidth="5" />
                              <circle r="5" cx="10" cy="10" stroke="white" strokeWidth="5" strokeDasharray={`calc(${100*pickCount/games.length} * 31.42 / 100) 31.42`} transform="rotate(-90) translate(-20)" />
                              <circle r="5" cx="10" cy="10" strokeWidth="2" fill={pickCount == games.length ? "white" : "black"} />
                              <line x1="7" y1="10.5" x2="9.25" y2="12.75" strokeWidth="1" />
                               <line x1="9" y1="12.5" x2="13" y2="8.5" strokeWidth="1" />
                            </svg>
                            {pickCount}/{games.length} Selected</div>*/}
                            </div>
                        )}
                        
                </>)}
            </div>
        </>
    );
};

export default NewGame;
