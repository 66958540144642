import React, { useState, useEffect, useCallback } from 'react';
import './Wrapped.css'; // Import the default styles
import { fetchAllPicks, fetchData } from './services/api';

const Wrapped = () => {
    const [data, setPicks] = useState([]);
    const [board, setBoard] = useState({});
    const [loading, setLoading] = useState(true);
    const [teams, setTeams] = useState([]);
    const [teamsData, setTeamsData] = useState([]);
    const [savedProfile, setSavedProfile] = useState(JSON.parse(localStorage.getItem('profile')) || {});
    const [top, setTop] = useState([]);


    const fetchGameData = useCallback(async (selectedWeek) => {
        try {
            const response = await fetchData(selectedWeek);
            return response.events || [];
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    }, []);

    useEffect(() => {
        const fetchPicks = async () => {
            try {
                const picksData = await fetchAllPicks();
                setPicks(picksData);
            } catch (error) {
                console.error('Error fetching picks:', error);
            }
        };
        fetchPicks();
    }, []);

    useEffect(() => {
        const fetchAndProcessData = async () => {
            setLoading(true);
            const weeks = Array.from({ length: 18 }, (_, i) => i + 1); // Weeks 1 to 18
            const TEAMS = [];
            const TEAMSDATA = {};
            const allGamesData = {};

            for (const selectedWeek of weeks) {
                const eventData = await fetchGameData(selectedWeek);
                eventData.forEach((row) => {
                    const game = row.competitions[0];
                    allGamesData[game.id] = {};

                    const team1 = game.competitors[0].team.name;
                    const team2 = game.competitors[1].team.name;

                    if (!TEAMS.includes(team1)) TEAMS.push(team1);
                    if (!TEAMS.includes(team2)) TEAMS.push(team2);

                    TEAMSDATA[team1] = {
                        color: game.competitors[0].team.color,
                        city: game.competitors[0].team.location,
                        logo: game.competitors[0].team.logo
                        };
                    TEAMSDATA[team2] = {
                        color: game.competitors[1].team.color,
                        city: game.competitors[1].team.location,
                        logo: game.competitors[1].team.logo
                    };

                    allGamesData[game.id]["team"] = game.competitors.find(comp => comp.winner)?.team.name || '';
                });
            }

            if (data && data.length > 0) {
                processPicksData(allGamesData, TEAMS);
            }
            setTeams(TEAMS);
            setTeamsData(TEAMSDATA);
        };

        const processPicksData = (allGamesData, TEAMS) => {
            const newBoard = {};

            data.forEach((item) => {
                const user = item.Email;
                let picks;
                try {
                    picks = JSON.parse(item.Picks) || {};
                } catch {
                    picks = {};
                }

                if (!newBoard[user]) {
                    newBoard[user] = { points: 0, correct: 0, wrong: 0 };
                }

                Object.entries(picks).forEach(([matchupID, pick]) => {
                    const matchData = allGamesData[matchupID];
                    if (matchData?.team && !matchData[user]) {
                        matchData[user] = "Scored";

                        newBoard[user][pick.pick] = (newBoard[user][pick.pick] || 0) + 1;

                        if (matchData.team === pick.pick) {
                            newBoard[user].points += pick.val;
                            newBoard[user].correct += 1;
                        } else {
                            newBoard[user].wrong += 1;
                        }
                    }
                });
            });

            setBoard(newBoard);
            const p = calculateTeamPickPercentageAcrossUsers(newBoard, TEAMS);
            setTop(p);
            setLoading(false);
        };

        if (data && data.length > 0) {
            fetchAndProcessData();
        }
    }, [data, fetchGameData]);

    const findTopNKeys = (obj, n) => {
        const sortedEntries = Object.entries(obj).sort((a, b) => b[1] - a[1]);
        return sortedEntries.slice(0, n).map(([key, value]) => ({ key, value }));
    };

    const calculateTeamPickPercentageAcrossUsers = (boardData, teamsList) => {
        const allPicks = {};
        const percentages = {};
        var topPicks = [];

        Object.entries(boardData).forEach(([user, picks]) => {
            teamsList.forEach(team => {
                allPicks[team] = (allPicks[team] || 0) + (picks[team] || 0);
            });
        });

        Object.entries(boardData).forEach(([user, picks]) => {
            if (user == savedProfile.Email) {
            //if (user == "alianatesta@gmail.com") {
                percentages[user] = teamsList.reduce((acc, team) => {
                    if (picks[team]) {
                        acc[team] = Math.round(100 * (picks[team] / allPicks[team]));
                    }
                    return acc;
                }, {});
                console.log(percentages);
                topPicks = findTopNKeys(percentages[user], 3);
            }
            
        });

        return topPicks;
    };

    return (
        <>
            {loading ? (
                <div className="wrapped">
                    <div className="year">
                        2024
                    </div>
                    <div className="loading"></div>
                </div>
            ) : (
                    <>
                        <div className="wrapped" style={{ background: `#${teamsData[top[0]?.["key"]]?.["color"]}` }}>
                            <div className="slant" ></div>
                            <div className="year">
                            2024
                            </div>
                            <div className="info">
                                <div className="user">
                                    <div className="name">
                                        {savedProfile.Name}
                                    </div>
                                    <div className="points">
                                        {board[savedProfile.Email]?.points} 
                                    </div>
                                    <div className="subtext">Total Points</div>
                                </div>
                                <div className="picks">
                                    
                                    <div className="percent-circle">
                                        <div className="correct">
                                            {Math.round(100 * board[savedProfile.Email]?.correct / (board[savedProfile.Email]?.correct + board[savedProfile.Email]?.wrong))}%
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="160" width="160" viewBox="0 0 20 20">
                                            <circle r="7.5" cx="10" cy="10" stroke="#ffffff80" strokeWidth="2" fill="transparent"></circle>
                                            <circle r="7.5" cx="10" cy="10" stroke="white" strokeWidth="2" fill="transparent" strokeDasharray={`calc(${ Math.round(100 * board[savedProfile.Email]?.correct / (board[savedProfile.Email]?.correct + board[savedProfile.Email]?.wrong))} / 100 * 47.12) 47.12`} transform="rotate(-90) translate(-20)"></circle>
                                        </svg>
                                    </div>
                                    <div className="subtext">
                                        {board[savedProfile.Email]?.correct} Correct Picks
                                    </div>
                                </div>
                            </div>
                            <br />
                            <h1>Your Top Teams</h1>
                            <div className="teams">
                                {top.map((team, index) => (
                                    <div className={`team team${index}`} key={index}>
                                        <div className="rank">{index + 1}</div>
                                        <div
                                            className="logo"
                                            style={{ backgroundImage: `url(${teamsData[team?.["key"]]?.["logo"]})` }}
                                        ></div>
                                        <div className="name">
                                            <div className="mascot">{team?.["key"]}</div>
                                            <div className="city">{teamsData[team?.["key"]]?.["city"]}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                </>
                /*<table>
                    <thead>
                        <tr>
                            <th>User</th>
                                <th>#1</th>
                                <th>#2</th>
                                <th>#3</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(board).map(([user]) => (
                            <tr key={user}>
                                <td>{user}</td>
                                {top[user]?.map(({ key }, idx) => (
                                    <td key={idx}>{key}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>*/
            )}
        </>
    );
};

export default Wrapped;
