import React, { useState, useEffect, useCallback } from 'react';
import './App.css'; // Import the default styles
import { fetchAllPicks, fetchData } from './services/api';
import { doc, getFirestore, collection, getDocs, getDoc, updateDoc } from 'firebase/firestore';

const ProcessLeaderboard = ({ }) => {

    const [data, setPicks] = useState([]);
    const [board, setBoard] = useState({});
    const [loading, setLoading] = useState(true);
    const [displayNames, setDisplayNames] = useState({});
    const [pics, setPics] = useState({});
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        console.log("Processing Leaderboard!");
    }, []);

    const fetchGameData = useCallback(async (selectedWeek) => {
        try {
            const response = await fetchData(selectedWeek);
            return response.events;
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    }, []);

    useEffect(() => {
        // Function to fetch picks and update state
        const fetchPicks = async () => {
            try {
                const picksData = await fetchAllPicks();
                setPicks(picksData);
            } catch (error) {
                console.error('Error fetching picks:', error);
            }
        };

        // Call the fetchPicks function when the component mounts
        fetchPicks();

    }, []); // Empty dependency array to ensure effect runs only once after initial render


    useEffect(() => {
        const fetchAndProcessData = async () => {
            setLoading(true);
            const start = 0;
            const weeks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
            const weeksToFetch = weeks.slice(start);

            const allGamesData = {};
            const newBoard = {};
            const firestore = getFirestore();

            const TEAMS = [];

            for (const selectedWeek of weeksToFetch) {
                const eventData = await fetchGameData(selectedWeek);
                for (const [key, row] of Object.entries(eventData)) {
                    const game = row.competitions[0];
                    allGamesData[game.id] = {};

                    if (!TEAMS.includes(game.competitors[0].team.name)) {
                        TEAMS.push(game.competitors[0].team.name)
                    }
                    if (!TEAMS.includes(game.competitors[1].team.name)) {
                        TEAMS.push(game.competitors[1].team.name)
                    }

                    if (game.competitors[0].winner) {
                        allGamesData[game.id]["team"] = game.competitors[0].team.name;
                    } else if (game.competitors[1].winner) {
                        allGamesData[game.id]["team"] = game.competitors[1].team.name;
                    }
                }
            }

            for (const [key, row] of Object.entries(data)) {
                const item = row;
                const user = item.Email;
                let picks;

                try {
                    picks = JSON.parse(item.Picks);
                } catch (error) {
                    picks = {};
                }

                if (!newBoard[user]) {
                    newBoard[user] = { points: 0, correct: 0, wrong: 0 };
                }

                var total = 0;
                var correct = 0;
                var wrong = 0;
                for (const [matchupID, pick] of Object.entries(picks)) {
                    if (allGamesData && allGamesData[matchupID] && allGamesData[matchupID]["team"] && allGamesData[matchupID][user] != "Scored") {
                        allGamesData[matchupID][user] = "Scored";
                        if (newBoard[user][pick.pick] > 0) {
                            newBoard[user][pick.pick] += 1;
                        }
                        else {
                            newBoard[user][pick.pick] = 1;
                        }

                        if (allGamesData[matchupID]["team"] === pick.pick) {
                            total += pick.val;
                            correct++;
                        }
                        else {
                            wrong++;
                        }
                    }
                }

                newBoard[user]["points"] += total;
                newBoard[user]["correct"] += correct;
                newBoard[user]["wrong"] += wrong;
            }


            setBoard(newBoard);
            setTeams(TEAMS);

            const updatePromises = Object.keys(newBoard).map(async (user) => {
                const userDocRef = doc(firestore, 'Users', user);
                return updateDoc(userDocRef, {
                    Points: newBoard[user]["points"],
                    Correct: newBoard[user]["correct"],
                    Wrong: newBoard[user]["wrong"]
                });
            });

        };

        fetchAndProcessData();

    }, [data, fetchGameData]); // Dependency on 'data' and 'fetchGameData'

    const findTopNKeys = (obj, n) => {
        // Convert object to array of [key, value] pairs
        const entries = Object.entries(obj);

        // Sort entries by value in descending order
        entries.sort((a, b) => b[1] - a[1]);

        // Get the top N keys
        return entries.slice(0, n).map(entry => ({ key: entry[0], value: entry[1] }));
    };

    const calculateTeamPickPercentageAcrossUsers = (newBoard, teams) => {

        const allPicks = {};
        const percentages = {};
        const max = {};
        for (const [user, picks] of Object.entries(newBoard)) {
            for (const team of teams) {
                if (picks[team]) {
                    if (allPicks[team]) {
                        allPicks[team] += picks[team];
                    }
                    else {
                        allPicks[team] = picks[team];
                    }
                }       
            }
        }
        for (const [user, picks] of Object.entries(newBoard)) {
            percentages[user] = {};
            for (const team of teams) {
                if (picks[team]) {
                    percentages[user][team] = Math.round(100 * (picks[team] / allPicks[team]));
                }
            }
            max[user] = {};
            const [highest, secondHighest, thirdHighest] = findTopNKeys(percentages[user], 3);

            max[user][1] = highest;
            max[user][2] = secondHighest;
            max[user][3] = thirdHighest;
        }
        
        return max;
    }
    const top = calculateTeamPickPercentageAcrossUsers(board, teams);

    return (
        <>
            {/*<table>
                <thead>
                    <tr><th>User</th>
                        {teams.map((team) => (
                            <td key={team}>


                            </td>
                        ))}
                    </tr>
                                </thead>
            <tbody>
                    {Object.entries(board).map(([user, columns]) => {
                        

                        return (
                            <tr key={user}>
                                <td>{user}</td>
                                <td>{top[user][1] ? top[user][1]["key"] : null}</td>
                                <td>{top[user][2] ? top[user][2]["key"] : null}</td>
                                <td>{top[user][3] ? top[user][3]["key"] : null}</td>
                            </tr>
                        )
                    }
            )}
                </tbody>
            </table>*/}
        </>
    );
}

export default ProcessLeaderboard;