import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import { fetchUserProfile2 } from './services/api';


function Profile({ onLogout, onPageChange }) {
    // Initialize profile state to an empty object if local storage is not set

    const [savedProfile, setSavedProfile] = useState(JSON.parse(localStorage.getItem('profile')) || {});

    useEffect(() => {
        const getProfile = async () => {
            try {
                const u = await fetchUserProfile2(savedProfile.Email, setSavedProfile);

                setNewName(u.Name);
                setIcon(u.Icon);
                setColor(u.Color);
            }
            catch {

            }
        }
        getProfile();
    }, []);

    const [updating, setUpdating] = useState(false);
    const [newName, setNewName] = useState(savedProfile.Name || '');
    const [color, setColor] = useState(savedProfile.Color || '');
    const [icon, setIcon] = useState(savedProfile.Icon || '');

    const updateProfile = async (email) => {
        try {
            setUpdating(true);
            const firestore = getFirestore();

            // Reference the document using the email as the document ID
            const userDocRef = doc(firestore, 'Users', email);

            // Update the document with the new name and picture
            await updateDoc(userDocRef, {
                Name: newName,
                Color: color,
                Icon: icon
                // Add any other fields you want to update
            });

            // Update localStorage and state accordingly
            localStorage.setItem('profile', JSON.stringify({
                Email: email,
                Name: newName,
                Color: color,
                Icon: icon
            }));
            setSavedProfile({
                Email: email,
                Name: newName,
                Color: color,
                Icon: icon
                // Add any other fields you want to update
            });

        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setUpdating(false);
        }
    };

    useEffect(() => {
        const handleStorageChange = () => {
            setSavedProfile(JSON.parse(localStorage.getItem('profile')));
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    //const pictureUrl = savedProfile.Picture ? savedProfile.Picture.replace('public\\', '') : ''; // Check if Picture field is available

    const handleIconSelect = (icon) => {
        setIcon(icon);
    };

    const handleColorSelect = (color) => {
        setColor(color);
    };

    const isUpdateEnabled = true;

    return (
        <div className="profile page">

            <div className="profile-section">
                <h2>Manage Your Profile</h2>
                <div className="displayName">
                    <span className="title">Display Name</span>
                    <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
                </div>
                <div className="emailAddress">
                    <span className="title">Email</span>
                    <div className="disabledField">{savedProfile.Email}</div>
                </div>
                <div className="profilePic">
                    <span className="title">Profile Picture</span>
                    <div className="Field">
                        {icon && <div id="actualIcon" className={`actualIcon ${color}`}><img src={`${icon}.png`} alt={`${icon}`}/></div>}
                        <div className={`icons`}>
                            <div className={`icon  football ${icon === 'football' ? 'active' : ''}`} onClick={() => handleIconSelect('football')}><img src='football.png' alt="Football" /></div>
                            <div className={`icon  stadium ${icon === 'stadium' ? 'active' : ''}`} onClick={() => handleIconSelect('stadium')}><img src='stadium.png' alt="Stadium" /></div>
                            {/*<div className={`icon  trophy ${icon === 'trophy' ? 'active' : ''}`} onClick={() => handleIconSelect('trophy')}><img src='trophy.png' alt="Trophy" /></div>*/}
                            <div className={`icon  gibbsgrey ${icon === 'gibbsgrey' ? 'active' : ''}`} onClick={() => handleIconSelect('gibbsgrey')}><img src='gibbsgrey.png' alt="redg" /></div>
                            <div className={`icon  pizza ${icon === 'pizza' ? 'active' : ''}`} onClick={() => handleIconSelect('pizza')}><img src='pizza.png' alt="Pizza" /></div>
                            {/*<div className={`icon  robot ${icon === 'robot' ? 'active' : ''}`} onClick={() => handleIconSelect('robot')}><img src='robot.png' alt="Robot" /></div>*/}
                            <div className={`icon  brain ${icon === 'brain' ? 'active' : ''}`} onClick={() => handleIconSelect('brain')}><img src='brain.png' alt="brain" /></div>
                            <div className={`icon  camel ${icon === 'camel' ? 'active' : ''}`} onClick={() => handleIconSelect('camel')}><img src='camel.png' alt="camel" /></div>
                            <div className={`icon  plant ${icon === 'plant' ? 'active' : ''}`} onClick={() => handleIconSelect('plant')}><img src='plant.png' alt="plant" /></div>
                            <div className={`icon  trex ${icon === 'trex' ? 'active' : ''}`} onClick={() => handleIconSelect('trex')}><img src='trex.png' alt="trex" /></div>
                            <div className={`icon  ufo ${icon === 'ufo' ? 'active' : ''}`} onClick={() => handleIconSelect('ufo')}><img src='ufo.png' alt="ufo" /></div>
                            <div className={`icon  bomb ${icon === 'bomb' ? 'active' : ''}`} onClick={() => handleIconSelect('bomb')}><img src='bomb.png' alt="bomb" /></div>
                            <div className={`icon  diamond ${icon === 'diamond' ? 'active' : ''}`} onClick={() => handleIconSelect('diamond')}><img src='diamond.png' alt="diamond" /></div>
                            <div className={`icon   ${icon === 'cash' ? 'active' : ''}`} onClick={() => handleIconSelect('cash')}><img src='cash.png' alt="cash" /></div>
                            <div className={`icon   ${icon === 'fall' ? 'active' : ''}`} onClick={() => handleIconSelect('fall')}><img src='fall.png' alt="fall" /></div>
                            {/*<div className={`icon   ${icon === 'maple' ? 'active' : ''}`} onClick={() => handleIconSelect('maple')}><img src='maple.png' alt="maple" /></div>*/}
                            <div className={`icon   ${icon === 'orca' ? 'active' : ''}`} onClick={() => handleIconSelect('orca')}><img src='orca.png' alt="orca" /></div>
                            {/*<div className={`icon   ${icon === 'orca2' ? 'active' : ''}`} onClick={() => handleIconSelect('orca2')}><img src='orca2.png' alt="orca2" /></div>*/}
                        </div>

                        <div className="colors">
                            {/* Add event handlers to select color */}
                            <div className={`color darkyellow ${color === 'darkyellow' ? 'active' : ''}`} onClick={() => handleColorSelect('darkyellow')}></div>
                            <div className={`color grey1 ${color === 'grey1' ? 'active' : ''}`} onClick={() => handleColorSelect('grey1')}></div>
                            <div className={`color black ${color === 'black' ? 'active' : ''}`} onClick={() => handleColorSelect('black')}></div>
                            
                            

                            <div className={`color red ${color === 'red' ? 'active' : ''}`} onClick={() => handleColorSelect('red')}></div>
                            <div className={`color red1 ${color === 'red1' ? 'active' : ''}`} onClick={() => handleColorSelect('red1')}></div>
                            <div className={`color red2 ${color === 'red2' ? 'active' : ''}`} onClick={() => handleColorSelect('red2')}></div>

                            <div className={`color orange ${color === 'orange' ? 'active' : ''}`} onClick={() => handleColorSelect('orange')}></div>
                            <div className={`color orange1 ${color === 'orange1' ? 'active' : ''}`} onClick={() => handleColorSelect('orange1')}></div>
                            <div className={`color orange2 ${color === 'orange2' ? 'active' : ''}`} onClick={() => handleColorSelect('orange2')}></div>
                            
                            <div className={`color yellow ${color === 'yellow' ? 'active' : ''}`} onClick={() => handleColorSelect('yellow')}></div>
                            <div className={`color yellow2 ${color === 'yellow2' ? 'active' : ''}`} onClick={() => handleColorSelect('yellow2')}></div>
                            <div className={`color yellow3 ${color === 'yellow3' ? 'active' : ''}`} onClick={() => handleColorSelect('yellow3')}></div>

                            <div className={`color green ${color === 'green' ? 'active' : ''}`} onClick={() => handleColorSelect('green')}></div>
                            <div className={`color green2 ${color === 'green2' ? 'active' : ''}`} onClick={() => handleColorSelect('green2')}></div>
                            <div className={`color green3 ${color === 'green3' ? 'active' : ''}`} onClick={() => handleColorSelect('green3')}></div>
                            
                            <div className={`color blue ${color === 'blue' ? 'active' : ''}`} onClick={() => handleColorSelect('blue')}></div>
                            <div className={`color blue1 ${color === 'blue1' ? 'active' : ''}`} onClick={() => handleColorSelect('blue1')}></div>
                            <div className={`color blue2 ${color === 'blue2' ? 'active' : ''}`} onClick={() => handleColorSelect('blue2')}></div>

                            <div className={`color dblue ${color === 'dblue' ? 'active' : ''}`} onClick={() => handleColorSelect('dblue')}></div>
                            <div className={`color dblue1 ${color === 'dblue1' ? 'active' : ''}`} onClick={() => handleColorSelect('dblue1')}></div>
                            <div className={`color dblue2 ${color === 'dblue2' ? 'active' : ''}`} onClick={() => handleColorSelect('dblue2')}></div>

                            <div className={`color purple ${color === 'purple' ? 'active' : ''}`} onClick={() => handleColorSelect('purple')}></div>
                            <div className={`color purple1 ${color === 'purple1' ? 'active' : ''}`} onClick={() => handleColorSelect('purple1')}></div>
                            <div className={`color purple2 ${color === 'purple2' ? 'active' : ''}`} onClick={() => handleColorSelect('purple2')}></div>

                            <div className={`color pinky ${color === 'pinky' ? 'active' : ''}`} onClick={() => handleColorSelect('pinky')}></div>
                            <div className={`color pink1 ${color === 'pink1' ? 'active' : ''}`} onClick={() => handleColorSelect('pink1')}></div>
                            <div className={`color pink2 ${color === 'pink2' ? 'active' : ''}`} onClick={() => handleColorSelect('pink2')}></div>

                            <div className={`color special ${color === 'special' ? 'active' : ''}`} onClick={() => handleColorSelect('special')}></div>
                            <div className={`color special1 ${color === 'special1' ? 'active' : ''}`} onClick={() => handleColorSelect('special1')}></div>
                            <div className={`color special2 ${color === 'special2' ? 'active' : ''}`} onClick={() => handleColorSelect('special2')}></div>

                            <div className={`color red49 ${color === 'red49' ? 'active' : ''}`} onClick={() => handleColorSelect('red49')}></div>



                            {/*<div className={`color darkred ${color === 'darkred' ? 'active' : ''}`} onClick={() => handleColorSelect('darkred')}></div>
                            <div className={`color darkorange ${color === 'darkorange' ? 'active' : ''}`} onClick={() => handleColorSelect('darkorange')}></div>
                            
                            <div className={`color darkgreen ${color === 'darkgreen' ? 'active' : ''}`} onClick={() => handleColorSelect('darkgreen')}></div>
                            <div className={`color darkblue ${color === 'darkblue' ? 'active' : ''}`} onClick={() => handleColorSelect('darkblue')}></div>
                            <div className={`color darkpurple ${color === 'darkpurple' ? 'active' : ''}`} onClick={() => handleColorSelect('darkpurple')}></div>
                            <div className={`color pink ${color === 'pink' ? 'active' : ''}`} onClick={() => handleColorSelect('pink')}></div>
                            <div className={`color teal ${color === 'teal' ? 'active' : ''}`} onClick={() => handleColorSelect('teal')}></div>
                            <div className={`color brown ${color === 'brown' ? 'active' : ''}`} onClick={() => handleColorSelect('brown')}></div>
                            <div className={`color cyan ${color === 'cyan' ? 'active' : ''}`} onClick={() => handleColorSelect('cyan')}></div>
                            <div className={`color lime ${color === 'lime' ? 'active' : ''}`} onClick={() => handleColorSelect('lime')}></div>
                            <div className={`color magenta ${color === 'magenta' ? 'active' : ''}`} onClick={() => handleColorSelect('magenta')}></div>
                            <div className={`color olive ${color === 'olive' ? 'active' : ''}`} onClick={() => handleColorSelect('olive')}></div>
                            <div className={`color indigo ${color === 'indigo' ? 'active' : ''}`} onClick={() => handleColorSelect('indigo')}></div>
                            <div className={`color maroon ${color === 'maroon' ? 'active' : ''}`} onClick={() => handleColorSelect('maroon')}></div>
                            <div className={`color turquoise ${color === 'turquoise' ? 'active' : ''}`} onClick={() => handleColorSelect('turquoise')}></div>
                            <div className={`color violet ${color === 'violet' ? 'active' : ''}`} onClick={() => handleColorSelect('violet')}></div>
                            <div className={`color peach ${color === 'peach' ? 'active' : ''}`} onClick={() => handleColorSelect('peach')}></div>*/}
                        </div>

                    </div>
                </div>
                <button disabled={!isUpdateEnabled || updating} onClick={() => updateProfile(savedProfile.Email)}>
                    {updating ? 'Updating...' : 'Update'}
                </button>
            </div>
            <br />
            
            <button id="logout" onClick={onLogout}>Logout of GIBBS</button>
        </div>
    );
}

export default Profile;
