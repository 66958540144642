import React, { useState, useEffect } from 'react';
import { Header, Footer } from './Global';

const AddToHomeScreenButton = ({ isLoggedIn, login }) => {
    const [message, setMessage] = useState('');
    const [game, setGame] = useState(true);
    const [browser, setBrowser] = useState('');

    useEffect(() => {
        // Check if the current browser supports service workers
        const isServiceWorkerSupported = 'serviceWorker' in navigator;

        // Check if the current browser is running in standalone mode (installed as a web app)
        const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;

        // Check if the user agent indicates a mobile device
        const isMobile = /Mobi/i.test(navigator.userAgent);

        // Check the user agent to determine the browser being used
        const userAgent = navigator.userAgent;
        const isChrome = /Chrome/i.test(navigator.userAgent);

        const isFacebook = /FBAN|FBAV/i.test(userAgent);
        const isMessenger = /Messenger/i.test(userAgent);
        const isInstagram = /Instagram/i.test(userAgent);

        if (isInstagram) {
            setBrowser('NativeAppBrowser');
            setMessage(
                <span>
                    Open GIBBS in your regular browser, instead of the Instagram browser.<br /><br />
                    <strong>Step 1:</strong> Tap the three dots in the top right corner of your screen.<br /><br />
                    <strong>Step 2:</strong> Select "Open in external browser".<br /><br />
                </span>
            );
        }
        else if (isFacebook || isMessenger) {
            setBrowser('NativeAppBrowser');
            setMessage(
                <span>
                    Open GIBBS in your regular browser, instead of the Facebook browser.<br /><br />
                    <strong>Step 1:</strong> Tap the <img src="share.png" alt="Share Icon" /> in the bottom right corner of your screen.<br /><br />
                    <strong>Step 2:</strong> Select "Open in Browser" or "Open in Safari".<br /><br />
                </span>
            );
        }
        else if (userAgent.indexOf('CriOS') !== -1) {
            setBrowser('ChromeIOS');
            setMessage(<span>To play GIBBS, click the <img src="share.png" alt="Share Icon" /> in the corner and then select "Add to Home Screen"</span>);
        } 
        else if (isChrome) {
            if (userAgent.indexOf('Android') !== -1) {
                setBrowser('ChromeAndroid');
                setMessage(<span>To play GIBBS, click the <img src="share.png" alt="Share Icon" /> in the corner and then select "Install App"</span>);
            } else {
                setBrowser('ChromeOther');
                setMessage(<span>To play GIBBS, click the <img src="share.png" alt="Share Icon" /> in the corner and then select "Add to Home Screen"</span>);
            }
        }
        else if(userAgent.indexOf('Safari') !== -1) {
            setBrowser('Safari');
            setMessage(<span>To play GIBBS, click the <img src="share.png" alt="Share Icon" /> at the bottom and then select "Add to Home Screen."</span>);
        } else {
            setBrowser('Other');
            setMessage(<span>To play GIBBS, click the <img src="share.png" alt="Share Icon" /> in the corner and then select "Add to Home Screen."</span>);
        }


        // Set the message based on the results
        if (isMobile && !isInStandaloneMode) {
            setGame(false);
        } else {
            setGame(true);
        }
    }, []);

    console.log(game);
    return (
        <div>
            {(isLoggedIn || localStorage.getItem('login')) ? (
                <div>
                    <Header />
                </div>
            ) : (
                <div>
                    <div className="login-background">
                            {!game ? (
                                <div id="addArrow" className={browser}>
                                    {message}
                                    <img className="arrow" src="arrow.png" alt="Arrow icon" />
                            </div>
                        ) : null}
                    </div>
                    {game ? (
                        <div className="login">
                            <h1>Welcome to <span>GIBBS</span></h1>
                            <p>Make your NFL game predictions - Earn Points - Beat your friends!</p>
                            <button onClick={login}>Sign in with Google</button>
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default AddToHomeScreenButton;
