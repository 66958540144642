import './App.css'; // Import the default styles
import React, { useState, useEffect } from 'react';
import { doc, getFirestore, collection, getDocs, updateDoc } from 'firebase/firestore';

const ActivateNews = ({ }) => {
    var [news, setNews] = useState(null);
    const [refresh, setRefresh] = useState(false); // State to trigger refresh


    useEffect(() => {
        // Function to fetch picks and update state
        const fetchNews = async () => {
            try {
                //var news = await getNews();

                const firestore = getFirestore();

                const newsCollection = collection(firestore, 'News');

                // Get documents from the collection
                const newsArticles = await getDocs(newsCollection);

                // Process the snapshot
                var newsData = newsArticles.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                newsData = newsData
                    .filter(article => article.active === 0);

                // Update the state with the updated news data
                setNews(newsData);

            } catch (error) {
                console.error('Error fetching news:', error);
                fetchNews();
            }
        };

        fetchNews();

    }, [refresh]);

    const activate = async (id) => {
        const firestore = getFirestore();
        const docRef = doc(firestore, 'News', id); // Get a reference to the document using its ID

        try {
            await updateDoc(docRef, { active: 1 }); // Update the 'Activate' field to 1
            setRefresh(!refresh);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',

        });
    };

    const getDateDifference = (dateString) => {
        const givenDate = new Date(dateString);
        const now = new Date();
        const differenceInMilliseconds = now - givenDate;
        const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
        const differenceInHours = Math.floor(differenceInMinutes / 60);
        if (differenceInMinutes < 0) {
            return formatDate(dateString);
        }
        if (differenceInMinutes < 3) {
            return "Just Now";
        }
        if (differenceInMinutes < 60) {
            return differenceInMinutes + "m";
        }
        if (differenceInHours < 24) {
            return differenceInHours + "h";
        }
        return formatDate(dateString);

    };

    const handleClick = (key) => {
        activate(key);
    };

    
    return (
        <div className="page">
            <div className="news">
                <div className="goToNewsForm article">
                    <div className="headline">Click To Activate News</div>
                </div>
                <hr />
                {news ? (
                    Object.entries(news)
                        .map(([key, row], index, array) => {
                            const lastArticleDate = index > 0 ? array[index - 1][1].lastModified : null;
                            const showDateHeader = lastArticleDate !== null && formatDate(lastArticleDate) !== formatDate(row.lastModified);

                            return (
                                <React.Fragment key={key}>

                                    <div className={`article ${row.type}`} onClick={() => handleClick(row.id)}>

                                        {row.images[0]?.url && <img src={row.images[0].url} alt="Article Image" />}
                                        <div className="content">
                                            <div className="headline">{row.headline}</div>
                                            <div className="date">
                                                {getDateDifference(row.lastModified)}
                                                {row.byline ? ` - ${row.byline}` : ' - ESPN Staff'}
                                            </div>
                                        </div>
                                    </div>
                                    {index > -1 && <hr />}
                                </React.Fragment>
                            );
                        })
                ) : (
                    <div className="loadingNews">Loading...</div>
                )}
            </div>
        </div>
    );


}

export default ActivateNews;
